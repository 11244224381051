import React, {FC} from 'react'
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoViewer: FC<any> = ({ fileUrl, fileType, widthClass, padding = 0 }) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);

  const onReady = (player) => {
    playerRef.current = player;

    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  }

  const initPlayer = (fType) => {
    if (!videoRef.current) {
      return;
    }

    const options = {
      height: '320',
      autoplay: false,
      controls: true,
      enableSmoothSeeking: true,
      sources: [{
        src: fileUrl,
        type: fType
      }]
    }

    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);

        const box = document.querySelector(widthClass);
        const dem = getComputedStyle(player.el())

        const resizeVideo = () => {
          if (box && box?.offsetWidth > 0 && dem?.width && parseInt(dem.width) > box?.offsetWidth) {
            const boxWidth = box?.offsetWidth - padding*2 
            const height = boxWidth * 320 / parseInt(dem.width) 
            player.width(boxWidth);
            player.height(Math.round(height));
          }  
        }

        resizeVideo()
        window.addEventListener('resize', resizeVideo);
      });


      player.addClass('vjs-matrix');

    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }

  React.useEffect(() => {
    if (!fileType && fileUrl.includes("blob:")) {
      return;
    }

   if (!fileType) {
    fetch(fileUrl, {
      method: 'HEAD'
    }).then(reponse => {
      initPlayer(reponse.headers.get('Content-Type'))
    })  
   } else {
    initPlayer(fileType)
   }
  }, [fileUrl, fileType, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (   
  <div style={{ display: 'flex', justifyContent: 'center', background: 'black' }} data-vjs-player>
    <div ref={videoRef} />
  </div>)
}

export default VideoViewer;