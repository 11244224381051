import { User } from "./models";
import { OperationType } from './operation'
export interface UserCommunity {
  id: string
  name: string
  groups: UserGroup[]
  is_director: boolean
  is_operator: boolean
  is_showInAlphon: boolean,
  enabled: boolean,
}

export interface Description {
  category: string
  color: string
  fabColor: string
  icon: string
  iconColor: string
  iconName: string
  subCategory: string
  subCategoryKey: string
}
export interface Group {
  community_id: number
  description: Description | string
  duplex: number
  group_is_favourite: boolean
  group_is_member: boolean
  group_is_sender: boolean
  group_is_subscriber: boolean
  icon?: any
  id: number
  is_organizer?: boolean //in support groups i suppose
  members: any
  name: string
  orgainzers: number
  parent_id?: number
  type: GroupType
}
export interface UserGroup {
  community: number,
  group: Group
  is_favourite: boolean
  is_hide?: boolean //returned in getGroupMembers
  is_member: boolean
  is_organizer: boolean
  is_sender: boolean
  is_subscriber: boolean
  // parentOrganizer: number,//CHECK this one
  user: number
  userData?: User
}


export interface UserGroupUpdateReq extends UserGroupReq {
  group: number
}

export interface UserGroupCreateReq extends UserGroupReq {
}
interface UserGroupReq {
  member: number
  subscriber: number
  duplex: number
  favourite: boolean
  guid: string
  community: number
  id: number
  name: string
  description: string
  sender: number
}

export interface SendApiUser {
  nick: string,
  family: string,
  given: string,
  emails: string[],
  phones: string[],
}
export interface SendApiUserReq extends SendApiUser {
  guid: string,
  id: number,
  community: number
  user: number | undefined
  member: number
  sender: number
  organizer: number
  subscriber: number
  favourite: number

}
export interface CreateCommunityReq {
  id: number,
  guid: string,
  name: string,
}

export enum StrategyType {
  text = 'TEXT',
  media = 'MEDIA',
  pdf = 'PDF'
}

export enum FilesType {
  PDF = 'PDF',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}
export interface file {
  url: any
  type?: FilesType
  local: boolean
}
export type ID = number


export interface MessageResponseOp extends Op {
  user: number
  message: number
  status: number
  response: number
}

export interface MessageOp extends Op {
  sender: number
  type: string
  payload: string
  expiry?: string
  guid: string
  message?: number
  schedule?: string
  files?: any[]
  communities: number[]
  allCommunities?: 1,
  labels: string[]
}

interface Op {
  op: OperationType
  id?: number

}

export interface PayloadRequest {
  files: any,
  payload: any,
  type: any,
  blob: any
}
export enum deliveryType {
  MOBILE = 3,
  MAIL = 2,
  SMS = 1,
  MOBILE2 = 4
}
export interface SurveyAnswer {
  id: string
  text: string
  color: number
}

export enum MessageDialogMode {
  Regular = 'regularMessage',
  Survey = 'surveyMessage'
}

export enum StatusType { MUTED = 1, OUTGOING = 2, SENT = 3, FAILED = 4, RECEIVED = 5, READ = 6, RESPONSED = 7 }

export enum InfoType {
  DELIVERY = 'delivery',
  SLIKA = 'slika',
  PORTAL = 'portal',
  THIRD = 'third',
  SUPPORT = 'support'
}
export interface ModuleType {
  module_id: number
  type: InfoType
  external_url: string | null
  title: string | null
  description: string | null
  community_ref: number | null | undefined
  icon_color: string | null
  icon_text: string | null
  icon_name: string | null | undefined
  icon_text_settings: string | null
  icon: string | null
  org_id: string | null
  provider: string
}

export enum ModuleEnum {
  DELIVERY = 'delivery',
  THIRD = 'third',
  PORTAL = 'portal',
  SUPPORT = 'support',
  SLIKA = 'slika',
}
export enum ProviderEnum {
  MAOF = 'maof',
  NONE = 'none',
  SLIKA = 'slika',
  MEKOME = 'mekome'
}
export enum GroupType {
  REGULAR = 'regular',
  SUPPORT = 'support',
}
export interface Info {
  id: number,
  name: string,
  smsCounter: number,
  extraData: string | null,
}
export interface Community {
  id: number,
  name: string,
  labels: string[]
}
// export interface ThirdItem {
//   module_id: number
//   type: string
//   external_url: string,
//   title: string
//   description: string
//   // icon:string
//   // data:string
//   icon_color: string
//   icon_text: string
//   // icon_name: string
//   // icon_text_settings:string
// }
